import { useState } from 'react'

import { LogoBlack } from 'src/components/App/Header/LogoBlack'
import { BookmarkLink } from 'src/components/App/Header/MainHeader/BookmarkLink'
import { ClassStackLink } from 'src/components/App/Header/MainHeader/ClassStackLink'
import { MainHeaderDrawer } from 'src/components/App/Header/MainHeader/MainHeaderDrawer'
import { SearchBar } from 'src/components/App/Header/MainHeader/SearchBar'
import { NavLinks } from 'src/components/App/Header/Nav/NavLinks'
import { UserMenu } from 'src/components/App/Header/UserMenu/UserMenu'
import { MorphismWrapper } from 'src/components/MorphismWrapper'
import { MobileSearch } from './MobileSearch'

const TourDialog = dynamic(
  () => import('src/components/TourDialog').then((mod) => mod.TourDialog),
  { ssr: false },
)

export const MainHeader = () => {
  const [isTourOpen, setTourOpen] = useState(false)

  return (
    <MorphismWrapper className="h-[var(--main-header-mobile-height)] lg:h-[var(--main-header-height)]">
      <div className="container mx-auto flex items-center justify-between gap-8 !px-4 py-0">
        <section className="flex flex-1 items-center gap-8">
          <div>
            <LogoBlack to='/' />
          </div>
          <SearchBar className="hidden lg:block" />
        </section>

        <section className="hidden flex-1 items-center justify-end gap-6 lg:flex">
          <NavLinks />
          <BookmarkLink />
          <ClassStackLink />
          <UserMenu />
        </section>
        <section className="flex items-center gap-4 lg:hidden">
          <MobileSearch />
          <MainHeaderDrawer />
        </section>
        {isTourOpen && (
          <TourDialog
            isOpen={isTourOpen}
            onClose={() => setTourOpen(false)}
            tourSlug='product-tour'
          />
        )}
      </div>
    </MorphismWrapper>
  )
}
