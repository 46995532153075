import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Search } from 'src/components/Icon/SearchNew'
import { SearchBar } from 'src/components/App/Header/MainHeader/SearchBar'
import { Chevron } from 'src/components/Icon/Chevron'

export const MobileSearch = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="radius-none bg-transparent pl-2.5 pr-0 text-xl leading-5 text-black focus:outline-none"
      >
        <Search />
      </button>
      <div
        className={twMerge(
          `gradient-light fixed bottom-0 left-0 right-0 top-0 cursor-pointer transition-[visibility,opacity]
          duration-200 ease-linear`,
          [isOpen ? 'visible opacity-100' : 'invisible opacity-0'],
        )}
      >
        <div className="relative flex p-4">
          <button
            onClick={() => setIsOpen(false)}
            className="mr-2 border-transparent focus:outline-none"
          >
            <Chevron className="h-5 w-5 rotate-90" />
          </button>
          <SearchBar className="block lg:hidden" onSearch={() => setIsOpen(false)} />
        </div>
      </div>
    </>
  )
}
